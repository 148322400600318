import * as React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useGroupBy,
  useExpanded,
  useFilters,
  TableOptions,
  Column,
} from "react-table";
import CustomCell from "./CustomCell";
import "./CustomTable.styles";
import {
  StyledTable,
  StyledThead,
  Tcell,
  StyledTrow,
  Pagination,
  Item,
  PageButton,
  PaginationDropDown,
  PageInput,
  TableFoot,
  ToolBarSpacer,
} from "./CustomTable.styles";
import { TableToolbar, wrapTextSignal } from "./TableToolBar";
import {
  LocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorageService";

import { useAtomValue } from "jotai";
import { adminAppState } from "../../app/store";
import { signal } from "@preact/signals-react";

export const tableInstanceSignal = signal<any>(null);

interface CustomTableProps<T extends Record<string, unknown>>
  extends TableOptions<T> {
  columns: any[];
  data: any[];
  updateMyData?: Function;
  skipPageReset?: boolean;
  scoresToChange?: any;
  setScoresToChange?: any;
  selectedTable?: any;
  toggleDrawer?: Function;
  hidden: string[];
  sortedBy: {
    desc: boolean;
    id: string;
  }[];
  hideSpacer?: boolean;
}

const CustomTable: React.FC<React.PropsWithChildren<CustomTableProps<any>>> = ({
  columns,
  data,
  updateMyData,
  skipPageReset,
  scoresToChange,
  setScoresToChange,
  selectedTable,
  toggleDrawer,
  hidden,
  sortedBy,
  hideSpacer = false,
}) => {
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(hidden);
  const { filterToggle } = useAtomValue(adminAppState);
  const hooks = [
    useGlobalFilter,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  ];

  const defaultColumn = React.useMemo<Partial<Column<any>>>(
    () => ({
      disableFilter: true,
      // disableGroupBy: true,
      Filter: <></>,
      Cell: CustomCell,
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
        pageIndex: 0,
        hiddenColumns: hiddenColumns,
        sortBy: sortedBy,
      } as any,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: false,
      autoResetRowState: !skipPageReset,
      autoResetGlobalFilter: false,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      setHiddenColumns,
      updateMyData,
      selectedTable,
      scoresToChange,
      setScoresToChange,
      toggleDrawer,
    } as any,
    ...hooks
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setGroupBy,
    setFilter,
    state: { pageIndex, pageSize, groupBy },
  }: any = instance;

  tableInstanceSignal.value = instance;

  const saveUserPageSize = (pageSize: number) => {
    setLocalStorageItem(LocalStorage.CLEARPLAY_PAGE_PREF, pageSize.toString());
    setPageSize(pageSize);
  };

  const getUserPageSize = React.useCallback(async () => {
    let pageSize;
    pageSize = getLocalStorageItem(LocalStorage.CLEARPLAY_PAGE_PREF);
    if (pageSize) {
      setPageSize(Number(pageSize));
    } else setPageSize(25);
  }, [setPageSize]);

  const footer = (
    <div>
      Showing:&nbsp;{page.length}&nbsp;of&nbsp;{data.length}
    </div>
  );

  React.useEffect(() => {
    // Get a list of available column ids
    const availableColumnIds = headerGroups
      .flatMap((group: any) => group.headers)
      .map((column: any) => column.id);
    // Loop through the filterToggle state
    for (const [key, value] of Object.entries(filterToggle)) {
      // Check if this key (column id) actually exists in the table
      if (availableColumnIds.includes(key)) {
        // If the filter is checked
        if (value.isChecked) {
          // Apply the filter
          setFilter(key, true);
        } else {
          // Otherwise, remove the filter
          setFilter(key, undefined);
        }
      }
    }
  }, [filterToggle, setFilter, headerGroups, data]);

  React.useEffect(() => {
    getUserPageSize();
    gotoPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTable]);

  if (!data.length) return <></>;

  return (
    <>
      <ToolBarSpacer hideSpacer={hideSpacer.toString()} />
      <TableToolbar
        instance={instance}
        {...{
          setGlobalFilter,
          setGroupBy,
          selectedTable,
          groupBy,
          setHiddenColumns,
        }}
      />
      <StyledTable {...getTableProps()} style={{}}>
        <StyledThead>
          {headerGroups.map(
            (group: {
              getHeaderGroupProps: () => JSX.IntrinsicAttributes;
              headers: {
                getHeaderProps: (arg0: any) => JSX.IntrinsicAttributes;
                getSortByToggleProps: () => any;
                render: (arg0: string) => any;
                isSorted: any;
                isSortedDesc: any;
              }[];
            }) => (
              <StyledTrow {...group.getHeaderGroupProps()}>
                {group.headers.map((column: any, index) => (
                  <Tcell
                    className={index === 0 ? "top-sticky-cell" : ""}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ▼"
                          : " ▲"
                        : ""}
                    </span>
                    {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                  </Tcell>
                ))}
              </StyledTrow>
            )
          )}
        </StyledThead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (
              row: {
                subRows: any;
                isExpanded: any;
                getToggleRowExpandedProps: any;
                getRowProps: (arg0: {}) => JSX.IntrinsicAttributes;
                cells: any[];
              },
              i: any
            ) => {
              prepareRow(row);
              return (
                <StyledTrow
                  {...row.getRowProps({
                    // onClick: () => setRowValue(row),
                  })}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <Tcell
                        {...cell.getCellProps()}
                        className={
                          cell.column.id === "comment"
                            ? "comments-column"
                            : index === 0
                            ? "sticky-cell grouped"
                            : "grouped"
                        }
                        isWrap={wrapTextSignal.value ?? false}
                      >
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <span
                              {...row.getToggleRowExpandedProps()}
                              style={{
                                marginRight: "20px",
                                marginBottom: "5px",
                              }}
                            >
                              {row.isExpanded ? "🔽" : "⏩"}
                            </span>{" "}
                            {cell.render("Cell")} ({row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render("Cell")
                        )}
                      </Tcell>
                    );
                  })}
                </StyledTrow>
              );
            }
          )}
        </tbody>
        <TableFoot>
          <tr>
            <td>{footer}</td>
          </tr>
        </TableFoot>
      </StyledTable>

      <Pagination className="pagination">
        <PageButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </PageButton>{" "}
        <PageButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </PageButton>{" "}
        <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </PageButton>{" "}
        <PageButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </PageButton>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <PageInput
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e: { target: { value: any } }) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <PaginationDropDown
          value={pageSize}
          onChange={(e: { target: { value: any } }) => {
            saveUserPageSize(Number(e.target.value));
          }}
        >
          {[10, 15, 20, 25, 50, 75, 100, 250, 500, data.length].map(
            (pageSize) => (
              <Item key={pageSize} value={pageSize}>
                Show {pageSize}
              </Item>
            )
          )}
        </PaginationDropDown>
      </Pagination>
    </>
  );
};

export default CustomTable;
