import { useCallback, useEffect, useRef } from 'react';
import { apiProviderTypeHome, tabToProviderPath } from '../app/app.types';
import { useTagManager } from './useTagManager';
import { AppRoutes } from '../app/routes';
import { returnIndexFromProvider, returnProviderFromIndex } from '../features/flix/flixTypes';
import { flixProviderSignal, isFetchingNextPageSignal } from '../features/flix/useFlix';
import { userLoadingSignal } from '../features/user/userSignals';
import { Queries, queryClient } from '../lib/react-query';
import { useHistory } from 'react-router-dom';
 

const providerSelectors =
    ["/amazon", "/apple", "/disney", "/max", "/netflix", "/search", "/"]

export const useHandleChange = () => {
  const history = useHistory()
  const { dataLayerPush } = useTagManager()
  const provider = flixProviderSignal.value
  const providerTab = returnIndexFromProvider(provider)
  const previousProviderTabRef = useRef(providerTab);
  const tagFiredRef = useRef(false);

  const handleChange = useCallback(
    async (newValue: number) => {
      if (isFetchingNextPageSignal.value || userLoadingSignal.value) return;
      if (newValue === returnIndexFromProvider(provider) || (!providerSelectors.includes(window.location.pathname) && window.location.pathname !== AppRoutes.HOME_EXPLORE)) return;
      queryClient.removeQueries([Queries.FETCH_FLIX])
      window.scrollTo({ top: 0 });
      flixProviderSignal.value = returnProviderFromIndex(newValue)
      history.push(tabToProviderPath[newValue])


      // Fire the tag if it hasn't been fired yet
      if (!tagFiredRef.current) {
        tagFiredRef.current = true;
        dataLayerPush({
            eventCategory: 'web.clearplay.com | all pages',
            eventAction: 'click event | all links',
            eventLabel: `${apiProviderTypeHome[newValue]}`,
            event: 'interactionEvent',
        });
      }
    },
    [provider, dataLayerPush]
  );

  useEffect(() => {
    if (providerTab !== previousProviderTabRef.current) {
      tagFiredRef.current = false;
      previousProviderTabRef.current = providerTab;
    }
  }, [providerTab]);

  return {handleChange};
};

export default useHandleChange;