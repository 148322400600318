import { API_TYPE, HTTP_METHOD, apiRequest } from "../../app/api";
import { CustomerSearchResult, StatsData } from "./admin.types";
import { AxiosError } from "axios";
import { ConstructCustomerUser } from "./customerModel";
import { User } from "../user/userTypes";

export type SearchCriteria = {
  email?: string;
  phone?: string;
  name?: string;
};

export const searchAccounts = async (
  body: SearchCriteria
): Promise<CustomerSearchResult[] | any> => {
  return await apiRequest(
    HTTP_METHOD.POST,
    API_TYPE.FILTER_API,
    `/admin/accounts/search`,
    body
  );
};

export const fetchCustomer = async (userId: string): Promise<User> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/accounts/details?user_id=${userId}& `
    );
    return ConstructCustomerUser(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const adminUpdateSubscription = async (body: {
  item_id: string;
  user_id: string;
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/accounts/subscriptions`,
      body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const getPaymentIntentAdmin = async (body: {
  customer_id: string;
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/accounts/payments/setup`,
      body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const addOrUpdateCardAdmin = async (body: {
  customer_id: string;
  setup_intent: string;
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/accounts/payments/confirm`,
      body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const setPasswordAdmin = async (body: {
  password: string;
  user_id: string;
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/accounts/passwords`,
      body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const createClock = async (body: {
  frozen_time: number;
  name: string;
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/accounts/testClocks`,
      body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const updateClock = async (body: {
  frozen_time: number;
  id: string;
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/accounts/testClocks`,
      body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const fetchClocks = async (): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/accounts/testClocks`
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const deleteClock = async (id: string): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.DELETE,
      API_TYPE.FILTER_API,
      `/admin/accounts/testClocks/${id}`
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const uploadImage = async (body: FormData): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/images?`,
      body,
      { "Content-Type": "multipart/form-data;" }
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const fetchAdminLinks = async () => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/dashboards/links?`
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const fetchAdminStats = async (): Promise<StatsData> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/dashboards/summaries`
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const adminResetPassword = async (body: {user_id: string, password: string}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/accounts/passwords`, 
      body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
