export interface Categories {
  category: string;
}

export interface Flix {
  asset_id: string;
  asset_identifier_id: string;
  category: string;
  child: null;
  description: string;
  duration: string;
  format_id: string;
  genre: null;
  identifiers: string;
  img_url: string | null;
  name: string;
  number: string;
  platform_id: string;
  platform_title: string;
  platforms: string;
  provider_id: string;
  rating_id: string;
  release_date: string;
  type_id: string;
  video_identifier: string;
  display_name: string;
  shelf_name: string;
  ticket_img_URL: string | undefined;
  ticket_punched_img_URL: string | undefined;
  asset_type_id?: string;
}

export enum ApiTypes {
  DISNEY = 10,
  NETFLIX = 9,
  MAX = 11,
  AMAZON = 7,
  APPLE = 13,
}

export const tabProviderMap: { [key: number]: number } = {
  0: 7,
  1: 13,
  2: 10,
  3: 11,
  4: 9,
  5: 100
};
export const providerToTabMap: { [key: number]: number } = {
  7: 0,
  13: 1,
  10: 2,
  11: 3,
  9: 4,
  100: 5
};

export const providerMap: { [key: number]: any } = {
  0: 'Unsupported',
  1: 'YouTube',
  2: 'Roku',
  3: 'Chromecast',
  4: 'DVD Player 747',
  5: 'Blu-ray Player 888',
  6: 'Blu-ray Player 1126',
  7: 'Amazon',
  8: 'VUDU',
  9: 'Netflix',
  10: 'Disney',
  11: 'MAX',
  12: 'Plex',
  13: 'Apple',
};
export const displayProviderMap: { [key: number]: any } = {
  0: 'Unsupported',
  1: 'YouTube',
  2: 'Roku',
  3: 'Chromecast',
  4: 'DVD Player 747',
  5: 'Blu-ray Player 888',
  6: 'Blu-ray Player 1126',
  7: 'Amazon',
  8: 'VUDU',
  9: 'Netflix',
  10: 'Disney+',
  11: 'Max',
  12: 'Plex',
  13: 'Apple TV+',
  100: 'All Providers',
};

export const contentUrl: { [key: number]: string } = {
  7: 'https://www.amazon.com/gp/video/detail/',
  9: 'https://www.netflix.com/watch/',
  10: 'https://www.disneyplus.com/',
  11: 'https://play.max.com/',
  13: 'https://tv.apple.com/',
};

export enum PaymentPlans {
  Monthly = 'plan_CxVZlt8v0gq80h',
  Price_Annual = 'price_1NVhzuGeyewV43rPZeAZxsnx',
  Price_Monthly = 'price_1NIj0YGeyewV43rPbfCTaPN0',
  Annual = 'plan_D5N29IsuVlxbXY',
  NoSubscription = 'No plan selected',
  Canceled = '',
}

// Search hit objects

export interface HitObject {
  platforms: Platform[];
  display_name: string;
  name: string;
  img_URL: string;
  objectID: string;
  _highlightResult: HighlightResult;
  __position: number;
  asset_type_id: string;
}

interface HighlightResult {
  display_name: Displayname;
}

interface Displayname {
  value: string;
  matchLevel: string;
  matchedWords: any[];
}

export interface Platform {
  asset_id: number;
  asset_identifier_id: number;
  partner_identifier: string;
  platform_id: number;
  platform_title: string;
  asset_type_id: string;
  platform_url: string
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const apiProviderTypeHome: { [key: number]: any } = {
  0: 'Amazon',
  1: 'Apple TV+',
  2: 'Disney+',
  3: 'Max',
  4: 'Netflix',
};
export const providerToIdMap: { [key: string]: number } = {
  'Amazon': 7,
  'Apple TV+': 13,
  'Disney+': 10,
  'Max': 11,
  'Netflix': 9,
};

export const sortTypeMap: { [key: number]: string } = {
  1: 'Manual',
  2: 'Theatrical Asc (oldest first)',
  3: 'Theatrical Desc (newest first)',
  4: 'Dynamic (WIP)',
};

export const tabToProviderPath: { [key: number]: any } = {
  0: '/amazon',
  1: '/apple',
  2: '/disney',
  3: '/max',
  4: '/netflix',
  100: '/search',
};