import styled from "styled-components/macro";
import { Colors } from "../../theme";
import { Switch, TextField } from "@mui/material";

export const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "42px !important",
  height: "26px !important",
  padding: "0 !important",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#673ab7",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00897b",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: Colors.blue,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#39393D",
    opacity: 1,
  },
}));

export const CellInput = styled(TextField)`
  && {
    width: auto;
    &:after {
      border: ${Colors.colorBlue};
    }
    &:before {
      border: ${Colors.colorBlue};
    }
    input {
      color: ${Colors.colorBlue};
    }
    textarea {
      color: ${Colors.colorBlue};
    }
    input {
      color: ${Colors.colorBlue};
      &::-webkit-calendar-picker-indicator {
        color: ${Colors.colorBlue};
      }
    }

    label.MuiInputLabel-root {
      color: ${Colors.colorBlue};
    }

    label.Mui-focused {
      color: ${Colors.colorBlue};
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid#8899a6;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid #8899a6; // adjust as needed, default is 2px for Material-UI v4
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid #8899a6; // this styles the line when the input is focused
    }
  }
`;
export const NumInput = styled(CellInput)`
  max-width: 25px;
`;

export const CopyValue = styled.span`
  cursor: pointer;
  color: #b3f5ff;
`;
export const Resolve = styled.span`
  cursor: pointer;
  color: ${props => props.value ? "#00875a" : ""};
`;

export const Version = styled(CellInput)`
  max-width: 50px;
`;

export const PulsingText = styled.span`
  @keyframes pulsate {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
  color: #ff0f0f;
  animation: pulsate 1s infinite ease-out;
`;

export const YesCell = styled.span`
  color: #00875a;
`;
export const NoCell = styled.span`
  color: #ff7452;
`;
export const GoToLinkCell = styled.span`
  color: ${Colors.colorBlue};
  cursor: pointer;
`;
export const OpenUrl = styled.a`
  color: ${Colors.colorBlue};
  cursor: pointer;
`;

export const CopyCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  svg {
    margin-right: 5px;
    :hover {
      cursor: pointer;
    }
  }
`;
