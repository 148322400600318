import { AxiosError } from "axios";
import { apiRequest, API_TYPE, HTTP_METHOD } from "../../../app/api";
import { getTokenData } from "../../../utils/localStorageService";
import {
  AdminTableSelection,
  CustomerComment,
  MovieScoreAsset,
  PlatformConfigObject,
} from "./tableTypes";
import {
  ConstructPlatformArray,
  ConstuctCustomerCommentArray,
  ConstructMovieAssetArray,
  ConstructShowAssetArray,
  ConstructDurationMismatchesArray,
} from "./tableModels";
import { ShelvesTable } from "../assets/assetTypes";

const platform = [AdminTableSelection.PLATFORM_CONTROLS] 
const asset = [
  AdminTableSelection.MOVIE_ASSET,
  AdminTableSelection.MOVIE_SCORE_ASSET,
  AdminTableSelection.CUSTOMER_COMMENTS,
  AdminTableSelection.SHOW_ASSET,
  AdminTableSelection.SHELVES,
] 

const reports = [
  AdminTableSelection.DURATION_MISMATCHES,
  AdminTableSelection.SKIP_ACCURACIES,
  AdminTableSelection.SNEEZ_TEST,
] 

const feedback = [
  AdminTableSelection.FEEDBACK_FILTERING
]

const getFetchType = (type: AdminTableSelection) => {
  if (asset.includes(type)) {
    return `assets/${type}`;
  } else if (platform.includes(type)) {
    return "platforms";
  } else if (reports.includes(type))
  return `reports/${type}`
  else if (feedback.includes(type))
    return `feedbacks/${type}`
};

export const fetchTableData = async (opts: {
  assetType: AdminTableSelection;
}) => {
  const fetchType = getFetchType(opts.assetType);
  if (fetchType === undefined) return
  try {
    const res = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/${fetchType}`,
      {}
    );

    switch (opts.assetType) {
      case AdminTableSelection.MOVIE_SCORE_ASSET: {
        return res as MovieScoreAsset[];
      }
      case AdminTableSelection.SHOW_ASSET: {
        return ConstructShowAssetArray(res);
      }
      case AdminTableSelection.PLATFORM_CONTROLS: {
        return ConstructPlatformArray(res);
      }
      case AdminTableSelection.MOVIE_ASSET: {
        return ConstructMovieAssetArray(res);
      }
      case AdminTableSelection.CUSTOMER_COMMENTS: {
        return ConstuctCustomerCommentArray(res);
      }
      case AdminTableSelection.DURATION_MISMATCHES: {
        return ConstructDurationMismatchesArray(res);
      }
      case AdminTableSelection.SHELVES: {
        return res as ShelvesTable[];
      }
      default:
        return res;
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const fetchShelfList = async (listId: string) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves/${listId}`
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const fetchClassifications = async () => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/filters/classificationVersions`,
      {}
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const fetchShelfSortTypes = async () => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves/sorts`
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const setShelfSortType = async (opts: {
  asset_list_type_id: number;
  asset_list_sort_id: number;
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves`,
      opts
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const setManualShelfOrder = async (opts: {
  asset_list_type_id: number;
  asset_lists?: { asset_list_id: number; display_order: number }[];
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves/computeShelfOrder`,
      opts
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const deleteShelf = async (opts: { asset_list_type_id: number }) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.DELETE,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves`,
      opts
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const setShelvesOrder = async (prioritiesList: []) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves/updatePriorities`,
      prioritiesList
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const updateShelf = async (opts: {
  name: string;
  description: string;
  isActive: boolean;
  asset_list_type_id: number;
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves`,
      opts
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const resolveIssue = async (opts: {options: Partial<CustomerComment>, api:string}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/${opts.api}`,
      opts.options
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const updatePlatform = async (options: any) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/platforms`,
      options
    );

    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const fetchPlatformConfigs = async(): Promise<PlatformConfigObject> => {
  try {
    const data = await apiRequest(HTTP_METHOD.GET, API_TYPE.FILTER_API,  `/platforms/configs`)
    return data
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
}