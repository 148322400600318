import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {signal} from '@preact/signals-react'
import {
  FocusContext,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import CustomSearchBox from './CustomSearchBox';

const initialQuery = signal('')

const SearchInputComponent: React.FC = () => {
  const { focusKey } = useFocusable();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get('q') || '';
    initialQuery.value = searchQuery;
  }, [location.search]);

  return (
    <FocusContext.Provider value={focusKey}>
      <CustomSearchBox
        id='search-input'
        autoFocus={true}
        placeholder={'Search by title'}
        initialQuery={initialQuery.value}
      />
    </FocusContext.Provider>
  );
};

export default SearchInputComponent;
