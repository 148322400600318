import { ToggleGroup } from '../../adminComponents/adminDrawers/DrawerComponent.styles';
import { useAtom } from 'jotai';
import { adminAppState } from '../../../app/store';
import { FormControlLabel, Switch } from '@mui/material';


const FilterBar: React.FC = ({ column  }: any) => {

  const { id, setFilter }: any = column;

  const [{ filterToggle }, setAdminAppState] = useAtom(adminAppState);
  // const isDualToggleColumn = id === "success";
  const isDualToggleColumn = null;


  const isChecked = filterToggle[id]?.isChecked || false;
  // dual toggle states
  const isTrueChecked = filterToggle[id]?.isTrueChecked || false;
  const isFalseChecked = filterToggle[id]?.isFalseChecked || false;
  
  const handleSingleChange = () => {
    const newIsChecked = !isChecked;
    setAdminAppState(prevState => ({
      ...prevState,
      filterToggle: {
        ...prevState.filterToggle,
        [id]: { ...prevState.filterToggle[id], isChecked: newIsChecked }
      },
    }));
    setFilter(newIsChecked); // Directly set the filter based on the toggle
  };

  // Handle change for dual toggles
  const handleDualChange = (target: 'true' | 'false') => {
    let newState = {
      isTrueChecked: false,
      isFalseChecked: false,
    };
  
    if (target === "true") {
      newState.isTrueChecked = !isTrueChecked; // Toggle the true filter
      // isFalseChecked is already set to false by default
    } else if (target === "false") {
      newState.isFalseChecked = !isFalseChecked; // Toggle the false filter
      // isTrueChecked is already set to false by default
    }
  
    setAdminAppState(prevState => ({
      ...prevState,
      filterToggle: {
        ...prevState.filterToggle,
        [id]: { ...prevState.filterToggle[id], ...newState }
      },
    }));
  
    // Now set the filter based on the updated state
    // If isTrueChecked is true, filter for true values, etc.
    if (newState.isTrueChecked) {
      setFilter(true); // Adjust as needed to match the expected filterValue format
    } else if (newState.isFalseChecked) {
      setFilter(false); // Adjust as needed
    } else {
      setFilter(undefined); // Clear the filter if neither is checked
    }
  };
  


  // not searchable, released, no poster, no imdb, classification
  const getLabel = (header: string) => {
    switch (header) {
      case 'Searchable':
        return 'Not Searchable';
      case 'Poster URL':
        return 'No Poster';
      case 'Released':
        return 'Released';
      case 'Imdb Id':
        return 'No ImdbId';
      case 'Class Id':
        return 'V2 Classification';
      case 'Reported':
        return 'Not Reported';
      case 'Resolved':
        return 'Not Resolved';
      case 'Date':
        return 'Last 30 Days';
      case 'Success':
        return 'Not Successful';
      case 'Reported At':
        return 'Not Reported';
      case 'Resolved At':
        return 'Not Resolved';
      case 'Un-Reproducible At':
        return 'Still Reproducible';
        
      default:
        return header;
    }
  };

  return (
    <div className="filter-bar">
      {isDualToggleColumn ? (
        <>
          <FormControlLabel
            control={<Switch checked={isTrueChecked} onChange={() => handleDualChange("true")} color="primary" />}
            label="True"
          />
          <FormControlLabel
            control={<Switch checked={isFalseChecked} onChange={() => handleDualChange("false")} color="primary" />}
            label="False"
          />
        </>
      ) : (
        <FormControlLabel
          control={<Switch checked={isChecked} onChange={handleSingleChange} color="primary" />}
          label={getLabel(column.Header)} // Assuming you pass the column header as part of the column object
        />
      )}
    </div>
  );
};

export default FilterBar;

