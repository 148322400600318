import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { ReplyAllOutlined } from "@mui/icons-material";
import styled from "styled-components/macro";
import { Colors } from "../../theme";

export const StyledTable = styled(Table)`
  width: 100%;
  z-index: 10;
  background: #15202b !important;
  color: #8899a6 !important;
  border-radius: 5px;
  border-collapse: inherit;
  .MuiTableCell-root {
    font-size: 0.8rem;
  }
`;

export const StyledThead = styled(TableHead)`
  th {
    padding: 10px 15px !important;
    color: #c270c0;
    position: sticky;
    top: ${(props) => props.dt === 'dt' ? '108px' : '64px'};
    background-color: black;
    padding-left: 16px;
    z-index: 10;
  }

  tr {
    padding: 5px;
    font-size: 0.8rem;
  }
  tr:nth-child(2) {
    th {
      text-align: left;
    }
  }
  .top-sticky-cell {
    position: sticky;
    left: 0;
    background-color: black;
    z-index: 12;
  }
`;
export const StyledTrow = styled(TableRow)`
  .MuiTableCell-root {
    font-weight: 900;
    font-size: 0.8rem;
    line-height: 1;
    padding: 2px 8px;
  }

  &:nth-child(even) {
    background: #192734 !important;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.18);
  }

  .sticky-cell {
    position: sticky;
    left: 0;
    background-color: black;
    z-index: 1;
  }
  .grouped {
   span {
    :hover {
      cursor: pointer;
    }
   }
  }
`;
export const Tcell = styled(({ isWrap, ...props }) => {
  return <TableCell {...props} />;
})`
  &.comments-column {
    min-width: 250px;
  }
  padding-top: 1px;
  padding-bottom: 1px;
  &.hover {
    &:hover {
      cursor: pointer;
    }
  }
  ${props => typeof props.isWrap === 'undefined' || !props.isWrap ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  ` : `
    white-space: normal;
    word-wrap: break-word;
  `}
`;

export const Pagination = styled.div`
 && {
   max-width: 90vw;
   margin: 0 auto;
   color: gray !important;
   padding-bottom: 50px;
   span {
     color: gray  !important;;
   }
 }
`;

export const DropDown = styled(Select)`
  && {
    min-width: 60px;
    background: transparent;
    border-radius: 5px;
    padding-left: 5px;

    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
    .MuiSelect-select:focus {
      background: transparent;
    }
  }
`;
export const Item = styled(MenuItem)``;

export const PageButton = styled(Button)`
  && {
    margin-top: 2px;
    background: #22303c;
    border: 1px solid gray !important;
    color: gray !important;
    &:hover {
      color: white !important;
      border: 1px solid white !important;
    }
  }
`;
export const PaginationDropDown = styled(DropDown)`
  && {   margin-top: 3px;
    .MuiOutlinedInput-input {
      background: #22303c;
      border: 1px solid gray;
      color: gray;
      padding: 10px;
      min-width: 95px;
      div {
        margin-top: 3px;
      }
    }
  }
`;
export const GroupingDropdown = styled(DropDown)`
  background: #22303c;
  border: 1px solid gray;
  color: gray;
  padding: 3px;
  width: 250px;
  height: 32px;
  margin-left: 15px;
  div {
    margin-top: 3px;
  }
`;
export const PageInput = styled(TextField)`
  background: #22303c;

  color: gray;
  border-radius: 5px;
  padding: 4px;
  padding-left: 6px;
  input {
    color: gray;
  }
  div {
    margin-top: 3px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .MuiOutlinedInput-input {
    border: 1px solid gray;
      padding: 10px;
      border-radius: 5px;
  }
`;

export const TableFoot = styled(TableFooter)`
  div {
    margin-top: 10px;
  }
`;

export const ClearFilter = styled.span`
  color: white;
  cursor: pointer;
  &:hover {
    color: gray;
  }
  margin-left: 5px;
  width: 100px;
`;

export const GroupByOutlined = styled(({ isToggled, ...props }) => <ReplyAllOutlined {...props} />)`
  color: gray;
  transform: ${(props) => !props.isToggled ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
export const GroupCheckBox = styled(Checkbox)`
  .MuiTouchRipple-child {
    background-color: ${Colors.colorBlue};
  }
`;

export const ToolBarSpacer = styled.div`
min-height: 110px;
width: 1px;
display: ${(props) => props.hideSpacer === 'true' ? 'none' : 'block'};
`