// hooks/useAssetFormState.ts
import { useState, useEffect, useRef } from "react";
import { AssetDrawerFormFields } from "../features/adminFeatures/assets/assetTypes";
import { getCleanedFormState, getTMDBValues, utcDate } from "./utils";
import { useSetAtom } from "jotai";
import { adminAppState } from "../app/store";
import { userMessageSignal } from "../features/user/userSignals";
import { patchAssetDetails } from "../features/adminFeatures/assets/asset.api";
import { movieDBSignal } from "../features/adminFeatures/assets/useAssetData";

export const useAssetFormState = (
  initialFormState: AssetDrawerFormFields,
  contentType: "movie" | "show",
  vals: any
) => {
  const [formState, setFormState] = useState(initialFormState);
  const [isSaved, setIsSaved] = useState(true);
  const [original, setOriginal] = useState<any>();

  const setInitialFormState = (contentType: "movie" | "show", vals: any) => {
    if (contentType === "movie") {
      return {
        display_name: vals["display_name"],
        name: vals["name"],
        imdb_id: vals["imdb_id"],
        release_date: utcDate(vals["release_date"]),
        credit_start_time: vals["credit_start_time"],
        duration: vals["duration"],
        display_duration: vals["display_duration"],
        video_duration: vals["video_duration"],
        searchable: vals["searchable"],
        filter_released: vals["filter_released"],
        classification_version_id: vals["classification_version_id"],
        platform_title: vals["platform_title"],
        video_id: vals["video_id"],
      };
    } else {
      return {
        episode_display_name: vals["episode_display_name"],
        episode_name: vals["episode_name"],
        episode_credit_start_time: vals["episode_credit_start_time"],
        episode_duration: vals["episode_duration"],
        episode_display_duration: vals["episode_display_duration"],
        episode_video_duration: vals["episode_video_duration"],
        episode_imdb_id: vals["episode_imdb_id"],
        series_imdb_id: vals["series_imdb_id"],
        series_display_name: vals["series_display_name"],
        series_searchable: vals["series_searchable"],
        series_platform_title: vals["series_platform_title"],
        series_video_id: vals["series_video_id"],
        episode_platform_id: vals["episode_platform_id"],
        episode_classification_version_id:
          vals["episode_classification_version_id"],
      };
    }
  };

  useEffect(() => {
    setFormState(setInitialFormState(contentType, vals));
    if (contentType === "movie" && vals["imdb_id"]) {
      getTMDBValues(vals["imdb_id"], "imdb_id");
    }
    if (contentType === "show" && vals["episode_imdb_id"]) {
      getTMDBValues(vals["episode_imdb_id"], "episode_imdb_id");
    }
  }, [contentType, vals]);

  useEffect(() => {
    setOriginal(setInitialFormState(contentType, vals));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(formState) === JSON.stringify(original)) {
      setIsSaved(true);
    } else setIsSaved(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return {
    formState,
    setFormState,
    isSaved,
    setIsSaved,
    original,
    setOriginal,
    setInitialFormState,
  };
};

// hooks/useCopyModalState.ts
export const useCopyModalState = () => {
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const copySeiresRef = useRef(false);

  const requestCopyAsset = async (copySeries: boolean) => {
    if (copySeries) {
      copySeiresRef.current = true;
    } else copySeiresRef.current = false;
    setOpenCopyModal(true);
  };

  const closeModal = () => {
    setOpenCopyModal(false);
  };

  return {
    openCopyModal,
    requestCopyAsset,
    closeModal,
    copySeiresRef,
    setOpenCopyModal,
  };
};

export const useAssetUpdate = (
  contentType: "movie" | "show",
  formState: any,
  isSaved: any,
  setIsSaved: Function,
  vals: { [x: string]: any },
  setOriginal: Function,
  setInitialFormState: Function,
  notSavedDialogOpen: any,
  setNotSavedDialogOpen: Function,
  updateMyData: Function,
  adminDrawer: { rowIndex: any }
) => {
  const setAdmin = useSetAtom(adminAppState);
  const { setMessage } = useChangesSaved();
  const SaveAsset = () => {
    if (isSaved) return;
    else updateAsset();
  };

  const updateAsset = async () => {
    setAdmin((prev) => ({ ...prev, loading: true }));

    const cleanedFormState = getCleanedFormState(contentType, formState);

    const type = contentType === "movie" ? "movies" : "shows";

    console.log('vals :>> ', vals);

    let updateData: any;
    if (contentType === "movie") {
      updateData = {
        ...cleanedFormState,
        asset_id: vals["asset_id"],
        filter_id: vals["filter_id"],
        asset_identifier_id: vals["asset_identifier_id"],
      };
    } else {
      updateData = {
        ...cleanedFormState,
        episode_platform_id: vals["episode_platform_id"],
        episode_asset_id: vals["episode_asset_id"],
        episode_asset_identifier_id: vals["episode_asset_identifier_id"],
        episode_number: vals["episode_number"],
        episode_partner_identifier: vals["episode_partner_identifier"],
        season_asset_id: vals["season_asset_id"],
        season_number: vals["season_number"],
        series_asset_id: vals["series_asset_id"],
        series_name: vals["series_name"],
        episode_filter_id: vals["episode_filter_id"],
       
      };
    }

    // Add console log to ensure updateData is constructed correctly
    console.log("updateData before API call :>> ", updateData);

    try {
      const data = await patchAssetDetails({
        options: updateData,
        type: type,
      });

      if (data) {
        setAdmin((prev) => ({ ...prev, loading: false }));
        setIsSaved(true);
        setMessage();

        setOriginal(setInitialFormState(contentType, updateData));

        Object.keys(updateData).forEach((key) => {
          updateMyData(Number(adminDrawer.rowIndex), key, updateData[key]);
        });

        if (notSavedDialogOpen) setNotSavedDialogOpen(false);
      }
    } catch (err: any) {
      setAdmin((prev) => ({ ...prev, loading: false }));
      console.log("err :>> ", err);
      userMessageSignal.value = {
        message: err.data.message,
        color: "red",
      };
    }
  };

  return { SaveAsset, updateAsset };
};

export const useResetDrawerState = (
  setFormState: (arg0: any) => void,
  initialFormState: any
) => {
  const setAdminAppState = useSetAtom(adminAppState);
  const resetDrawerState = () => {
    setAdminAppState((prev) => ({
      ...prev,
      adminDrawer: {
        open: false,
        values: undefined,
        rowIndex: undefined,
      },
    }));
    movieDBSignal.value = undefined;
    setFormState(initialFormState);
  };

  return { resetDrawerState };
};

export const useAdminTableRouting = () => {
  const setAdminTableHash = (hash: string) => {
    window.location.hash = hash;
  };
  return { setAdminTableHash };
};

export const useChangesSaved = () => {
  const setMessage = (message?: string) => {
    userMessageSignal.value = {
      message: message ? message : "Changes have been saved!",
      color: "green",
    };
  };

  return { setMessage };
};
