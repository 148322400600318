import { Resolve } from "../CustomCell.styles";
import { utcDate } from "../../utils";
import dayjs from "dayjs";
import { resolveIssue } from "../../../features/adminFeatures/tables/table.api";
import { AdminTableSelection } from "../../../features/adminFeatures/tables/tableTypes";
import { selectedTable } from "../../../features/adminFeatures/useAdminData";

interface IResolutionCell {
  column: any;
  row: any;
  value: string;
  updateMyData: Function;
}

export const ResolutionCell: React.FC<IResolutionCell> = ({
  column,
  row,
  value,
  updateMyData,
}) => {
  const selectedAdminTable = selectedTable.value;
  const isDurationMismatch =
    selectedAdminTable === AdminTableSelection.DURATION_MISMATCHES;
  const isFilteringFeedback = selectedAdminTable === AdminTableSelection.FEEDBACK_FILTERING;

  const resolve = (
    resolve: string | null,
    rowId: number,
    type: "resolve" | "report" | "unreproducible_at"
  ) => {
    const typeMap = {
      report: "reported_at",
      resolve: "resolved_at",
      unreproducible_at: "unreproducible_at"
    };
    const columnKey = typeMap[type];
    const payload = { [columnKey]: resolve } as any;
    let api = "";

    switch (selectedAdminTable) {
      case AdminTableSelection.DURATION_MISMATCHES:
        api = "reports/durationMismatches";
        payload.report_duration_mismatch_id = rowId;
        break;
      case AdminTableSelection.CUSTOMER_COMMENTS:
        api = "assets/scores/comments";
        payload.asset_score_id = rowId;
        break;
      case AdminTableSelection.FEEDBACK_FILTERING:
        api = "feedbacks/filterings";
        payload.feedback_filter_id = rowId;
        break;
    }

    resolveIssue({ options: payload, api: api }).then((res) => {
      if (res) {
        updateMyData(row.index, column.id, resolve, row);
      }
    });
  };

  let id = 0;
  if (isDurationMismatch) {
    id = row.values.report_duration_mismatch_id;
  } else if (isFilteringFeedback) {
    id = row.values.feedback_filter_id;
  } else {
    id = row.values.asset_score_id;
  }

  // Logic to determine the button text and action
  const resolveType = column.id.includes("unreproducible_at") ? "unreproducible_at" : (column.id === "resolved_at" ? "resolve" : "report");
  const now = new Date();
  const buttonText = resolveType.charAt(0).toUpperCase() + resolveType.slice(1).replace('_at', ' ');

  return (
    <span >
      {value ? (
        <Resolve value={value}
          onClick={() => {
            resolve("", id, resolveType);
          }}
        >
          {utcDate(value)}
        </Resolve>
      ) : (
        <Resolve
          onClick={() => {
            resolve(dayjs(now).format("YYYY-MM-DDThh:mm:ss"), id, resolveType);
          }}
        >
          {buttonText}
        </Resolve>
      )}
    </span>
  );
};
