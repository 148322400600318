import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../app/routes";
import { DeviceType } from "../../../features/user/userTypes";
import {
  getLocalStorageItem,
  getTokenData,
  removeLocalStorageItem,
  setTokenData,
} from "../../../utils/localStorageService";
import { FormContainer, PageWrapper, StepIndicator } from "../Form.Styles";
import { useCookies } from "react-cookie";
import { getStripeSignupUrl } from "../../../features/user/userApi";
import dayjs from "dayjs";
import { userDeviceSiganl, userLoadingSignal, userMessageSignal } from "../../../features/user/userSignals";
import { useUser } from "../../../features/user/useUser";
import { useAtomValue } from "jotai";
import { preLoginUser } from "../../../app/store";

const SigninCallback = () => {
  const history = useHistory();
  const welcome = JSON.parse(getLocalStorageItem("WELCOME_PATH")!);
  const { useMe,  } = useUser()
  const {data: user, refetch: fetchMe} = useMe()
  const codes = useAtomValue(preLoginUser);
  const redirect = useAtomValue(preLoginUser).redirect;
  const accessToken = new URLSearchParams(window.location.hash).get(
    "#id_token"
  );
  const [, setCookie] = useCookies(["clearplay_token"]);
  
  useEffect(() => {
    if (accessToken && window.location.pathname === AppRoutes.SIGNIN_CALLBACK) {
      setCookie("clearplay_token", `${accessToken}`, {
        domain: `${process.env.REACT_APP_DOMAIN}`,
        expires: dayjs().add(6, "months").toDate(),
      });
      setTokenData(accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navigateUser = async () => {
      const token = await getTokenData();
      if (token && window.location.pathname === AppRoutes.SIGNIN_CALLBACK) {
        if (token && !user?.email) { await fetchMe() }
        if (!user) return
        if (welcome && !user?.isActive) {
          const referral = codes.utm ? codes.utm : codes.referral || '';
          const url = await getStripeSignupUrl(referral, redirect)
          userLoadingSignal.value = false
          removeLocalStorageItem("WELCOME_PATH");
          window.open(url, "_self")
        }
        welcome
          ? history.push(AppRoutes.ACCOUNT_FOUND, { welcomePath: true })
          : history.push(AppRoutes.ACCOUNT_FOUND);
      } else if (window.location.pathname === AppRoutes.ACCOUNT_FOUND) {
        return;
      } else {
        history.push(AppRoutes.ENTER_EMAIL);
        userMessageSignal.value = 
        {message: "Something went wrong, please try again.",
        color: "red",
      }
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    };
    navigateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, user]);

  // useEffect(() => {
  //   if (userDeviceSiganl.value === DeviceType.DESKTOP ) {
  //     userLoadingSignal.value = true
  //   }
  // }, [userDeviceSiganl.value]);

  return (
    <PageWrapper>
      <FormContainer>
        <StepIndicator>
          Please close this window if it does not close automatically.
        </StepIndicator>
        {(userDeviceSiganl.value === DeviceType.MOBILE || userDeviceSiganl.value === DeviceType.TABLET) && (
          <>
            <StepIndicator>or</StepIndicator>
            <StepIndicator>
              Click on the account icon to view your account
            </StepIndicator>
          </>
        )}
      </FormContainer>
    </PageWrapper>
  );
};

export default SigninCallback;
