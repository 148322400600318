import { Category, ListWrapper } from "./SliderRow.styles";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { RowAsset } from "./RowAsset";
import { assetPagination, initializePaginationForShelf } from "./shelfUtil";
import { useFeature } from "../../../hooks/useFeature";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode } from "swiper/modules";
import next from "../../../assets/images/arrow-right-white.svg";
import { Asset, AssetList } from "../../adminFeatures/assets/assetTypes";
import { flixProviderSignal, useFlix } from "../../flix/useFlix";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type ListProps = {
  value: number;
  shelf: AssetList;
  index: number;
  provider: number;
};

const breakpoints = {
  2250: {
    slidesPerView: 6,
    spaceBetween: 15,
    initialSlide: 0,
  },
  1260: {
    slidesPerView: 6,
    spaceBetween: 15,
    initialSlide: 0,
  },

  520: {
    slidesPerView: 6,
    spaceBetween: 10,
    initialSlide: 0,
  },
  0: {
    slidesPerView: 6,
    spaceBetween: 5,
    initialSlide: 0,
  },
};

const SliderRow = React.memo<ListProps>((props) => {
  const { value, index, shelf } = props;
  const { ref, focusKey } = useFocusable({ trackChildren: true });
  const [prevArrowVisible, setPrevArrowVisible] = useState(false);
  const [nextArrowVisible, setNextArrowVisible] = useState(false);
  const { useFetchAndAddAssets } = useFlix();
  // Initialize the custom hook
  const mutate = useFetchAndAddAssets(shelf.asset_list_type_id);
  const { assetClick } = useFeature();
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  
  const onEnterPress = useCallback((focused: any) => {
    assetClick(false, focused.value, focused.film);
  }, []);

  const handleSlide = useCallback((direction: string) => {
    if (!swiperInstance) return;
    const isEnd = assetPagination.value[shelf.asset_list_type_id]?.isEnd.value
    if (direction === 'next' && !isEnd) {
      mutate(); 
    }

    const slideToIndex = direction === 'next' ? swiperInstance.activeIndex + 4 : swiperInstance.activeIndex - 4;
    swiperInstance.slideTo(slideToIndex);
  }, [mutate, shelf.asset_list_type_id, swiperInstance]);


  const updateSwiperConfiguration = useCallback(() => {
    if (!swiperInstance) return;

    const isBeginning = swiperInstance.isBeginning;
    const activeIndex = swiperInstance.activeIndex;

    setPrevArrowVisible(activeIndex > 0);
    setNextArrowVisible(!swiperInstance.isEnd);

    const opacityActiveIndex = swiperInstance.activeIndex + 2

    swiperInstance.slides.forEach(
      (slide: { style: { opacity: string } }, index: number) => {
        // Default opacity for non-visible slides
        slide.style.opacity = "0.5";
        // Determine the visible range of slides
        let rangeStart, rangeEnd;


        if (isBeginning) {
          rangeStart = 0;
          rangeEnd = 4; // Showing the first 5 slides fully
        } else if (swiperInstance.isEnd) {
          rangeStart = opacityActiveIndex  - 2; 
          rangeEnd = opacityActiveIndex + 2;
        } else {
          rangeStart = opacityActiveIndex - 2; // Default range for middle slides
          rangeEnd = opacityActiveIndex + 2;
        }
      
        // Make slides within the visible range fully opaque
        if (index >= rangeStart && index <= rangeEnd) {
          slide.style.opacity = "1";
        }
      }
    );


  }, [swiperInstance]);
  

  useEffect(() => {
    initializePaginationForShelf(shelf.asset_list_type_id, 0);
  }, [shelf.asset_list_type_id, flixProviderSignal.value]);

  useEffect(() => {
    if (swiperInstance && shelf?.assets?.length ) {
      updateSwiperConfiguration();
      swiperInstance.on('slideChange', updateSwiperConfiguration);
    }
    return () => {
      if (swiperInstance) {
        swiperInstance.off('slideChange', updateSwiperConfiguration);
      }
    };
  }, [shelf?.assets?.length, swiperInstance, updateSwiperConfiguration]);

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <ListWrapper
          ref={ref}
        >
          <Category>{shelf.name}</Category>
          <Swiper
            initialSlide={4}
            slidesPerView={"auto"}
            onSwiper={setSwiperInstance}
            onSlideChange={updateSwiperConfiguration}
            watchSlidesProgress={true}
            centeredSlides={false}
            breakpoints={breakpoints}
            modules={[Navigation, FreeMode]}
            draggable={true}
            onTouchStart={() => mutate()}
            allowTouchMove={true}
            freeMode={{
              enabled: true,
              momentum: true,
            }}
            navigation={{
              nextEl: ".next",
              prevEl: ".prev",
            }}
            id="home-swiper"
          >
            <div
              onClick={() => handleSlide("")}
              id={`prev-arrow-${index}`}
              className={`prev ${prevArrowVisible ? "" : "hidden"}`}
            >
              <img alt="prev" src={next} />
            </div>
            <div
              onClick={() => {
                handleSlide("next");
              }}
              id={`next-arrow-${index}`}
              className={`next ${nextArrowVisible ? "" : "hidden"}`}
            >
              <img alt="next" src={next} />
            </div>
            {shelf.assets?.map((asset: Asset, i: number) => (
              <SwiperSlide
                data-swiper-slide-index={i}
                key={`${i}-${shelf.name}`}
                className={
                  i === 0
                    ? "first-slide-margin"
                    : i === shelf.assets?.length - 1
                    ? "last-slide-margin"
                    : ""
                }
              >
                <RowAsset
                  handleSlide={handleSlide}
                  swiper={swiperInstance}
                  key={i + asset.display_name + "slider"}
                  asset={asset}
                  index={index}
                  assetIndex={i}
                  onEnterPress={onEnterPress}
                  value={value}
                  parentRef={ref}
                  rowTitle={shelf.name}
                />
              </SwiperSlide>
            ))}
            <SwiperSlide className="empty-slide"></SwiperSlide>
          </Swiper>
        </ListWrapper>
      </FocusContext.Provider>
    </>
  );
});

export { SliderRow };
